import React, { useState } from "react"
import { FiLoader } from "react-icons/fi"

export default function Button({
  onClick,
  onClickDisabled,
  href,
  children,
  style,
  id,
  className,
  variant = "default",
  Icon,
  disabled = false,
  forceLoading = false,
}) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: "text-lg stroke-1 mr-2 -ml-1",
    secondary: "text-lg stroke-1 mr-2 -ml-1",
    small: "stroke-1 mr-1 -mt-1",
    icon: "text-lg stroke-1",
    "icon-white": "text-lg stroke-1",
    arrow: "stroke-1 m-2 text-red hover:translate-x-3.5",
  }
  const styles = {
    default: `text-base py-2 lg:py-2 px-6 bg-yellow hover:bg-yellow-dark text-black mt-2`,
    secondary: `text-base py-1 lg:py-2 px-6 bg-yellow hover:bg-yellow-dark text-black mt-2`,
    small: `text-sm font-normal py-0 pt-1 px-3 bg-yellow hover:bg-yellow-dark text-black`,
    icon: `h-8 w-8 py-1 px-1 box-content bg-blue-dark text-black hover:opacity-75 rounded-full mt-2`,
    "icon-white": `h-8 w-8 py-1 px-1 box-content bg-white text-blue-dark hover:opacity-75 rounded-full mt-2`,
    arrow: "text-sm font-normal shadow-none hover:shadow-none",
    footer: "shadow-none h-8 w-8 mt-2",
  }

  return (
    <a href={href} target={href && href.match("http") ? "_blank" : ""} id={id}>
      <button
        onClick={async () => {
          if (disabled && onClickDisabled) return onClickDisabled()
          if (disabled) return
          setLoading(true)
          try {
            await onClick()
          } catch (error) {
            setLoading(false)
          }
          setLoading(false)
        }}
        disabled={(disabled && !onClickDisabled) || loading || forceLoading}
        style={style}
        className={`relative overflow-hidden flex justify-center items-center align-middle focus:outline-none select-none flex-shrink-0 transition-all duration-200 rounded-3xl shadow hover:shadow-md ${
          styles[variant]
        } ${className} ${disabled && "bg-gray-200 hover:bg-gray-200"}`}
      >
        {Icon && <Icon className={`${iconStyles[variant]}`} />}
        {children}
        {(loading || forceLoading) && (
          <div className={`absolute w-full h-full p-1 bg-gray-200`}>
            <FiLoader className="stroke-1 rotating box-border w-full h-full" />
          </div>
        )}
      </button>
    </a>
  )
}
